<!-- Default content if no content projection from outside -->
<ng-template #defaultContent>
  <div
    #defaultOptionsTrigger="cdkOverlayOrigin"
    (click)="optionsListGeneralComponent.toggleShow()"
    [ngClass]="{ opened: showOverlay$ | async }"
    cdkOverlayOrigin
    class="pointer-cursor interaction-list"
  >
    <span class="icon-options"></span>
  </div>

  <app-overlay-general
    #optionsListGeneralComponent
    (showChange)="showOverlay$.next($event)"
    [overlayPositions]="optionOverlayPositions"
    [trigger]="defaultOptionsTrigger"
  >
    <app-default-options-list
      (selectedOption)="registerSelectedOption($event)"
      [listItems]="listItems"
      [disabledActionList]="disabledActionList"
    >
    </app-default-options-list>
  </app-overlay-general>
</ng-template>

<!-- Custom content projected from outside -->
<!-- optionsTrigger is used even though it looks like it isn't -->
<!-- optionsTrigger is has to be declared in component because of the ngIf statement -->
<ng-container *ngIf="projectedContentTemplate; else defaultContent">
  <div #optionsTrigger="cdkOverlayOrigin" cdkOverlayOrigin>
    <ng-container *ngTemplateOutlet="projectedContentTemplate"></ng-container>
  </div>

  <app-overlay-general
    #optionsListGeneralComponent
    (showChange)="showOverlay$.next($event)"
    [overlayPositions]="optionOverlayPositions"
    [trigger]="optionsTrigger"
  >
    <app-default-options-list
      (selectedOption)="registerSelectedOption($event)"
      [listItems]="listItems"
      [disabledActionList]="disabledActionList"
    >
    </app-default-options-list>
  </app-overlay-general>
</ng-container>
